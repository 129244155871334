import { render, staticRenderFns } from "./PerformanceLabels.vue?vue&type=template&id=72bc67fd&scoped=true"
import script from "./PerformanceLabels.vue?vue&type=script&lang=js"
export * from "./PerformanceLabels.vue?vue&type=script&lang=js"
import style0 from "./PerformanceLabels.vue?vue&type=style&index=0&id=72bc67fd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72bc67fd",
  null
  
)

export default component.exports