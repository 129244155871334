<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <bb-icon
            icon="ic-insights-sharp"
            :size="32"
          />
        </template>
        <template #default> Performance Labels </template>
      </page-title>
    </page-section>
    <div v-if="!isProStore">
      <page-section>
        <upgrade-subscription>
          <template #content>
            This report is only available for paying subscription. You can test this feature during 30 days for FREE.
          </template>
        </upgrade-subscription>
      </page-section>
    </div>
    <div v-else>
      <page-section
        bottom-border
        v-if="isLoading"
      >
        <loader-ellipsis class="text-bb-neon-purple transform" />
        Loading Performance Labels
      </page-section>
      <div v-else>
        <page-section bottom-border>
          <hero>
            <template #image>
              <img
                src="/content/images/illustrations/stats.svg"
                alt="Let Bidbrain do the heavy lifting"
                class="sofa-guy"
              />
            </template>
            <template #title> Performance labels </template>
            <template #description>
              Performance labels can be used for building campaigns and gaining insights. On this page you can manage
              and customise how your performance labels are set.
            </template>
            <template #links>
              <bid-link
                :router-link="false"
                primary
                target="_blank"
                href-link="https://academy.bidbrain.com/faq"
              >
                <template #text> FAQ </template>
              </bid-link>
              <bid-link
                :router-link="false"
                primary
                target="_blank"
                href-link="https://academy.bidbrain.com/how-to-create-a-bidbrain-shopping-campaign"
              >
                <template #text> Our Guides </template>
              </bid-link>
              <bid-link
                :router-link="false"
                primary
                target="_blank"
                href-link="https://blog.bidbrain.com"
              >
                <template #text> Blog &amp; Articles </template>
              </bid-link>
            </template>
          </hero>
        </page-section>
        <page-section
          no-top-padding
          no-bottom-padding
          :bottom-border="borderBottom"
        >
          <div class="wrapper">
            <div class="performance-label-settings">
              <h1 class="mb-4">Performance label settings</h1>
              <p class="mb-8">
                Performance labels can be used for building campaigns and gaining insights. On this page you can manage
                and customise how your performance labels are set.
              </p>
              <the-button
                @click="recommendNewLimits"
                secondary
                :loading="newLimitLoading"
              >
                <template #icon>
                  <bb-icon
                    icon="ic-auto-fix"
                    :size="16"
                  />
                </template>
                <template #default> Recommend new limits </template>
              </the-button>
            </div>
            <div class="form">
              <base-input-text
                input-name="ROAS"
                input-id="ROAS-id"
                label="ROAS limit"
                v-model="form.roas"
                input-type="number"
                placeholder="Enter ROAS limit"
                :error="$v.form.roas.$error"
              >
                <template #suffix> % </template>
              </base-input-text>

              <base-input-text
                input-name="Clicks"
                input-id="Clicks-id"
                label="Clicks limit"
                v-model="form.clicks"
                input-type="number"
                placeholder="Enter Clicks limit"
                :error="$v.form.clicks.$error"
              />

              <the-button
                @click="activatePerformanceLabels"
                primary
                :loading="formLoading"
              >
                <template #icon>
                  <bb-icon
                    icon="ic-insights-sharp"
                    :size="16"
                  />
                </template>
                <template #default>
                  {{ isNew ? 'Activate performance labels' : 'Update limits' }}
                </template>
              </the-button>
            </div>
          </div>
        </page-section>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import IcCampaign from 'vue-material-design-icons/Tag.vue'
import UpgradeSubscription from '@/components/alert/UpgradeSubscription.vue'
import { mapGetters } from 'vuex'
import BidLink from '@/components/base/BidLink.vue'
import Hero from '@/components/shared/Hero.vue'
import BasePicker from '@/components/input/base/BasePicker.vue'
import { isBreakpointLargerThanViewPort } from '@/utils/breakpoints'
import { required, numeric } from 'vuelidate/lib/validators'
import BaseInputText from '@/components/input/base/BaseInputText.vue'
import Toast from '@/components/shared/Toast.vue'
import LoaderEllipsis from '@/components/loader/EllipsisLoader.vue'

export default {
  name: 'PerformanceLabels',
  components: {
    LoaderEllipsis,
    BaseInputText,
    BasePicker,
    Hero,
    BidLink,
    UpgradeSubscription,
    IcCampaign,
    PageSection,
    Page,
  },
  data() {
    return {
      isLoading: false,
      newLimitLoading: false,
      formLoading: false,
      isNew: false,
      form: {
        roas: '',
        clicks: '',
      },
    }
  },
  validations() {
    return {
      form: {
        roas: { required, numeric },
        clicks: { required, numeric },
      },
    }
  },
  watch: {
    async selectedStoreId() {
      this.form.roas = ''
      this.form.clicks = ''
      await this.loadPerformanceLabels()
    },
  },
  computed: {
    ...mapGetters({
      isProStore: 'store/isProStore',
      selectedStoreId: 'store/selectedStoreId',
    }),
    borderBottom() {
      return !isBreakpointLargerThanViewPort('md')
    },
  },
  methods: {
    async recommendNewLimits() {
      this.newLimitLoading = true
      try {
        const performanceLabels = await axios.get(`store/${this.selectedStoreId}/performance-labels/suggest`)
        if (performanceLabels.data?.error) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: performanceLabels.data?.error,
              type: 'error',
            },
          })
        } else {
          this.form.roas = performanceLabels.data?.data?.roas
          this.form.clicks = performanceLabels.data?.data?.clicks
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'New limits generated',
              type: 'success',
            },
          })
        }
        this.newLimitLoading = false
      } catch (e) {
        this.newLimitLoading = false
      }
    },
    async activatePerformanceLabels() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.formLoading = true
      try {
        const res = await axios.post(`store/${this.selectedStoreId}/performance-labels`, this.form)
        this.formLoading = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Performance labels activated',
            type: 'success',
          },
        })
      } catch (e) {
        this.formLoading = false
      }
    },
    async loadPerformanceLabels() {
      this.isLoading = true
      const performanceLabels = await axios.get(`store/${this.selectedStoreId}/performance-labels`)
      if (performanceLabels.data?.data?.roas === '' && performanceLabels.data?.data?.clicks === '') {
        this.isNew = true
      }
      this.form.roas = performanceLabels.data?.data?.roas
      this.form.clicks = performanceLabels.data?.data?.clicks
      this.isLoading = false
    },
  },
  async mounted() {
    await this.loadPerformanceLabels()
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  @apply grid grid-cols-1;
}

.performance-label-settings {
  @apply flex-shrink py-8 px-4 border-r-0;
}

.form {
  @apply py-8 px-4 flex flex-col gap-6;
}

@screen md {
  .wrapper {
    @apply grid-cols-2;
  }

  .performance-label-settings {
    @apply border-r border-bb-pale-grey py-8 px-12;
  }

  .form {
    @apply py-8 px-12;
  }
}
</style>
